import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { ThemeProvider } from "../components/theme-context"
import IconError from "../../assets/error.svg"

const NotFound = () => {
  return (
    <div>
      <IconError class="w-80 mx-auto" />
    </div>
  )
}

export default NotFound
