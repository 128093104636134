import React from 'react'

import Seo from './seo'
import Navigation from './navigation'
import Footer from './footer'

class Template extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      mounted :false
    };
  }

  render() {
    const { children } = this.props
    return (
      <div className={`${this.state.mounted ? 'animation-colors ease-linear duration-500' : ''} scroll-smooth bg-zinc-100 dark:bg-primaryDark min-h-screen`}>
        <Seo />
        <Navigation/>
        <main className="">{children}</main>
        <Footer />
      </div>
    )
  }

  componentDidMount() {
    setTimeout(function() {
      this.setState({mounted:true})
    }.bind(this), 1000)
  }
}

export default Template
